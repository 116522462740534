import "rc-collapse/assets/index.css";
import React from "react";
import { I18NProvider } from "../components/I18NProvider";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./privacy-policy.css";

const accordionItems = [
  {
    title: "Értelmező rendelkezések – Mi mit jelent?",
    content: (
      <div>
        <p>a.) Az Adatkezelő által kezelt adatok típusai:</p>
        <p>
          a/1.) személyes adat: a Személyes adat kapcsán érintettre vonatkozó
          bármely információ.
        </p>
        <p>
          a/2.) nem személyes adat: bármely, személyes adatnak nem minősülő
          adat.
        </p>
        <p style={{ textAlign: "center" }}>
          <img src="/kezelt_adatok.png" />
        </p>
        <p>b.) Az Adatkezelő adatkezelésében érintett személyek:</p>
        <p>
          b/1.) Adatkezelő: az a természetes vagy jogi személy, illetve jogi
          személyiséggel nem rendelkező szervezet, aki, vagy amely önállóan vagy
          másokkal együtt az adatok kezelésének célját meghatározza, az
          adatkezelésre (beleértve a felhasznált eszközt) vonatkozó döntéseket
          meghozza és végrehajtja, vagy az adatfeldolgozóval végrehajtatja. Nem
          személyes adatok Személyes adatok
        </p>
        <p>
          Magyarázat: Az Akt. kapcsán Adatkezelő első sorban az LA-IR Bt.
          Emellett Adatkezelőnek minősülhet még az LA-IR Bt-vel munkaviszonyban,
          vagy munkavégzésre irányuló egyéb jogviszonyban (pl.: megbízás) álló
          személy (a továbbiakban együtt: Munkavállaló).
        </p>
        <p>
          b/2.) Felhasználó: A honlapot nem Ügyfélként használó természetes
          személy.
        </p>
        <p>
          Magyarázat: Ha úgy használod a honlapot, hogy még nem regisztráltál az
          applikációban és nem is az iránt érdeklődsz, akkor Felhasználónak
          minősülsz.
        </p>
        <p>
          b/3.) Ügyfél: LA-IR Bt. applikációs szolgáltatását igénybe vevő,
          illetve azzal kapcsolatosan érdeklődő, ajánlatot kérő, vagy ajánlatot
          tevő természetes személy, jogi személy, vagy jogi személyiséggel nem
          rendelkező szervezet.
        </p>
        <p>
          Magyarázat: Ha igénybe veszed az LA-IR Bt. applikációs
          szolgáltatásait, valamint, ha azzal kapcsolatban érdeklődsz, illetve
          ajánlatot kérsz az LA-IR Bt-től, vagy azzal kapcsolatban ajánlatot
          teszel az LA-IR Bt-nek, akkor Ügyfélnek minősülsz.
        </p>
        <p>
          b/4.) Munkavállaló: a LA-IR Bt-vel munkaviszonyban, vagy munkavégzésre
          irányuló egyéb jogviszonyban (pl.: megbízási jogviszonyban) álló
          személy.
        </p>
        <p>
          Magyarázat: Ha a LA-IR Bt-nek, vagy a LA-IR Bt. megbízásából dolgozol,
          akkor Te Munkavállalónak minősülsz.
        </p>
        <p>
          b/5.) Személyes adat kapcsán érintett: bármely információ alapján
          azonosított vagy azonosítható természetes személy. Az Akt. értelmezése
          során Személyes adat kapcsán érintettnek tekintendő az a természetes
          személy, akire vonatkozó személyes adatot a Felhasználó, az Ügyfél,
          vagy a Munkavállaló bocsátja az Adatkezelő rendelkezésére. A Személyes
          adat kapcsán érintettek lehetnek: Felhasználók, Ügyfelek,
          Munkavállalók és egyéb személyek.
        </p>
        <p>Magyarázat: Személyes adat kapcsán érintett vagy akkor, ha:</p>
        <ul>
          <li>
            Felhasználó/ Ügyfél/ Munkavállaló vagy, és Veled kapcsolatba hozható
            olyan információkat adsz át a LA-IR Bt. részére, amelyek alapján
            téged azonosítani lehet;
          </li>
          <li>
            egy másik Felhasználó/ Ügyfél/ Munkavállaló ad át ilyen adatokat a
            LA-IR Bt-nek
          </li>
        </ul>
        <p>
          b/6.) Nem személyes adat kapcsán érintett: az a természetes személy,
          jogi személy, vagy jogi személyiséggel nem rendelkező szervezet, akire
          a nem személyes adat vonatkozik, vagy akivel az kapcsolatba hozható. A
          Nem személyes adat kapcsán érintettek lehetnek: Felhasználók,
          Ügyfelek, Munkavállalók és egyéb személyek.
        </p>
        <p>Magyarázat:</p>
        <p>Nem személyes adat kapcsán érintett vagy akkor, ha:</p>
        <ul>
          <li>
            Felhasználó/ Ügyfél/ Munkavállaló vagy, és rád vonatkozó olyan
            információkat adsz át a LA-IR Bt. részére, amelyek alapján Téged nem
            lehet azonosítani;
          </li>
          <li>
            Egy másik Felhasználó/ Ügyfél/ Munkavállaló ad át ilyen adatot a
            LA-IR Bt-nek
          </li>
        </ul>
        <p>
          A fentiek mellett Nem személyes adat kapcsán érintett lehet még az
          általad képviselt jogi személy, vagy jogi személyiséggel nem
          rendelkező szervezet, ha:
        </p>
        <ul>
          <li>Te</li>
          <li>vagy egy másik Felhasználó/ Ügyfél/ Munkavállaló</li>
        </ul>
        <p>rá vonatkozó adatot adtok át a LA-IR Bt-nek.</p>
        <p>
          <strong>
            Mielőtt tovább olvasol, nézd meg, hogy melyik csoportba tartozol!
            Fontos, hogy egyszerre akár több csoport tagja is lehetsz. A nyilak
            az adatok mozgását jelzik.
          </strong>
        </p>
        <p style={{ textAlign: "center" }}>
          <img src={"/adatkezelo.jpg"} />
        </p>
        <p>c.) Az Adatkezelő adatkezelésével kapcsolatos tevékenységek:</p>
        <p>
          c/1.) adatfeldolgozás: az adatkezelő megbízásából vagy rendelkezése
          alapján eljáró adatfeldolgozó által végzett adatkezelési műveletek
          összessége
        </p>
        <p>
          Magyarázat: Adatfeldolgozásról a gyakorlatban akkor beszélünk, ha az
          LA-IR Bt. megbíz egy külsős céget, személyt stb. azzal, hogy az általa
          kezelt adatokkal valamilyen adatkezelési műveletet végezzen el. Ilyen
          lehet pl. az adatok rendszerezése, csoportosítása, egységes
          adatbázisba átültetése, de ilyennek minősülhet akár az adatok alapján
          a könyvelési feladatok elvégzése is.
        </p>
        <p>
          c/2.) adatkezelés: az alkalmazott eljárástól függetlenül az adaton
          végzett bármely művelet vagy a műveletek összessége, így különösen
          gyűjtése, felvétele, rögzítése, rendszerezése, tárolása,
          megváltoztatása, felhasználása, lekérdezése, továbbítása,
          nyilvánosságra hozatala,összehangolása vagy összekapcsolása, zárolása,
          törlése és megsemmisítése, valamint az adat további felhasználásának
          megakadályozása, fénykép-, hang- vagy képfelvétel készítése, valamint
          a személy azonosítására alkalmas fizikai jellemzők (pl. ujj- vagy
          tenyérnyomat, DNSminta, íriszkép) rögzítése.
        </p>
        <p>
          Magyarázat: Jelen esetben minden olyan tevékenység adatkezelésnek
          minősül, amit az Adatkezelő a neki átadott adatokkal végez el.
        </p>
        <p>
          c/3.) hozzájárulás: a Személyes adat kapcsán érintett, vagy a Nem
          személyes adat kapcsán érintett akaratának önkéntes, határozott és
          megfelelő tájékoztatáson alapuló egyértelmű kinyilvánítása, amellyel
          nyilatkozat, vagy az akaratát félreérthetetlenül kifejező más
          magatartás útján jelzi, hogy beleegyezését adja a rá vonatkozó
          személyes adatok, vagy nem személyes adatok kezeléséhez. <br />
          Jelen Akt. vonatkozásában hozzájárulásnak minősül még a Felhasználó,
          az Ügyfél, vagy a Munkavállaló azon nyilatkozata is, amiben kijelenti,
          hogy az általa Adatkezelőnek átadott személyes és nem személyes
          adatokat a hatályos jogszabályi előírásoknak megfelelően adja át
          Adatkezelőnek, és biztosítja Adatkezelőt afelől, hogy az így átadott
          adatokat jogszerűen tudja kezelni minden további intézkedés nélkül
          (még akkor is, ha azok nem az adatokat átadó Felhasználóra, Ügyfélre,
          vagy Munkavállalóra vonatkoznak).
        </p>
        <p>Magyarázat: Hozzájárulásnak minősül az a nyilatkozat:</p>
        <ul>
          <li>
            amiben hozzájárulsz az általad Adatkezelőnek átadott személyes és/
            vagy nem személyes adataid kezeléséhez;
          </li>
          <li>
            amiben kijelented, hogy az általad Adatkezelőnek átadott, de másra
            vonatkozó személyes és nem személyes adatokat jogszerűen, illetve a
            Személyes adat kapcsán érintett hozzájárulásával adtad át. Ebben a
            nyilatkozatban azt is garantálod az Adatkezelőnek, hogy ezeket az
            adatokat az applikációs szolgáltatások nyújtása során, valamint
            azokkal kapcsolatban jogszerűen, illetve a Személyes adat kapcsán
            érintett hozzájárulásával kezelheti, és ehhez további intézkedést
            nem kell tennie
          </li>
        </ul>
        <p>
          c/4.) adatmegsemmisítés: az adatokat tartalmazó adathordozó teljes
          fizikai megsemmisítése.
        </p>
        <p>
          Magyarázat: Adatmegsemmisítés pl. az adatokat tartalmazó pendrive,
          vagy papírok megsemmisítése.
        </p>
        <p>
          c/5.) adattörlés: az adatok felismerhetetlenné tétele oly módon, hogy
          a helyreállításuk többé nem lehetséges.
        </p>
        <p>
          Magyarázat: Adattörlés pl. az adatok törlése a pendriveról, úgy hogy
          azokat már nem lehet visszaállítani.
        </p>
        <p>d.) Az Adatkezelő adatkezelésével kapcsolatos egyéb fogalmak:</p>
        <p>
          d/1.) adatállomány: az egy nyilvántartásban kezelt adatok összessége.
        </p>
        <p>
          d/2.) szerződés: az LA-IR Bt. és az Ügyfél által létrehozott, az LA-IR
          Bt-hez és az Ügyfélhez köthető, kölcsönös és egybehangzó
          jognyilatkozat, ami - az irányadó jogszabályokon túl - a LA-IR Bt. és
          az Ügyfél applikációs szolgáltatással kapcsolatos jogait és
          kötelezettségeit meghatározza.
        </p>
        <p>Magyarázat: Az a szerződés, amit Ügyfélként a LA-IR Bt-vel kötsz.</p>
        <p>
          d/3.) munkaszerződés: a LA-IR Bt. és a Munkavállaló által létrehozott,
          a LA-IR Bt-hez és a Munkavállalóhoz köthető, kölcsönös és egybehangzó
          jognyilatkozat, amellyel munkaviszonyt, vagy munkavégzésre irányuló
          egyéb jogviszonyt hoznak létre.
        </p>
        <p>
          Magyarázat: Az a szerződés, amit Munkavállalóként az LA-IR Bt-vel
          kötsz
        </p>
        <p>
          d/4.) applikáció: az a dogGo elnevezésű applikáció, amelyen keresztül
          LA-IR Bt. az applikációs szolgáltatást nyújtja.
        </p>
        <p>
          Magyarázat: Az az applikáció, amelyen keresztül a LA-IR Bt.
          applikációs szolgáltatásait igénybe tudod venni.
        </p>
        <p>
          d/5.) applikációs szolgáltatás: az LA-IR Bt által, a szerződés
          teljesítése keretében, az applikáción keresztül nyújtott szolgáltatás.
        </p>
        <p>
          Magyarázat: Az a szolgáltatás, amit az LA-IR Bt. a szerződés alapján
          az Ügyfél részére nyújt az applikáción keresztül.
        </p>
        <p>
          d/6.) hírlevél adatbázis: az Adatkezelő által üzemeltetett, első
          sorban e-mailcímeket tartalmazó zárt adatállomány, amelynek célja az
          Adatkezelő szolgáltatásairól szóló hírlevelek címzettjeihez, illetve
          jövőbeli címzettjeihez tartozó elérhetőségek hosszú távú tárolása és
          rendszerezése.
        </p>
        <p>
          d/7.) honlap: az adott domain névhez tartozó, kódolt formában
          szerkesztett lapok gyűjteménye, amelyek a tárhelyen (szerver oldal)
          vannak elhelyezve. Jelen Akt. alkalmazásában honlap alatt értjük a
          http://doggoapp.hu/ weboldalt, illetve az LA-IR Bt. által az
          applikációs szolgáltatás nyújtásához használt bármely más weboldalt.
        </p>
      </div>
    ),
  },
  {
    title:
      "Az adatkezelésre vonatkozó jogszabályok – Milyen jogszabályok szerint kezeli az adatokat az Adatkezelő?",
    content: (
      <div>
        <p>
          2.1. Adatkezelő jelen Akt-t – különös tekintettel, de nem
          kizárólagosan – az alábbiakban meghatározott jogszabályokkal
          összhangban alkotja meg:
        </p>
        <ul>
          <li>
            Az Európai Parlament és a Tanács 2016/679 rendelete (2016. április
            27.) a természetes személyeknek a személyes adatok kezelése
            tekintetében történő védelméről és az ilyen adatok szabad
            áramlásáról, valamint a 95/46/EK rendelet hatályon kívül
            helyezéséről (a továbbiakban: GDPR);
          </li>
          <li>Magyarország Alaptörvénye;</li>
          <li>
            A Polgári Törvénykönyvről szóló 2013. évi V. törvény (a
            továbbiakban: Ptk.);
          </li>
          <li>
            Az információs önrendelkezési jogról és az információszabadságról
            szóló 2011. évi CXII. törvény (a továbbiakban: Info tv.);
          </li>
          <li>
            A gazdasági reklámtevékenység alapvető feltételeiről és egységes
            korlátairól szóló 2008. évi XLVIII. törvény (a továbbiakban: Grt.)
          </li>
          <li>
            Az elektronikus kereskedelmi szolgáltatások, valamint az információs
            társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló
            2001. évi CVIII. törvény (a továbbiakban Ektv.);
          </li>
          <li>
            A Munka törvénykönyvéről szóló 2012. évi I. törvény (a továbbiakban:
            Mt.)
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Az adatkezelés célja – Miért kezel adatokat az Adatkezelő?",
    content: (
      <div>
        <p>3.1. Az adatkezelés célja Felhasználók és Ügyfelek esetében:</p>
        <ul>
          <li>Felhasználó, vagy Ügyfél által kért tájékoztatás megadása;</li>
          <li>
            Ügyfél – szerződés létrejöttéhez és a szerződés teljesítéshez
            szükséges – azonosítása;
          </li>
          <li>
            LA-IR Bt. szerződésszerű teljesítéséhez szükséges adatok,
            információk és iratok begyűjtése, feldolgozása és a szerződésszerű
            teljesítés keretében történő felhasználása;
          </li>
          <li>A szerződésszerű teljesítés dokumentálása;</li>
          <li>
            az Ügyfél hozzájárulása esetén a hírlevél adatbázisba történő
            felvétel, illetve a hírlevelek kiküldéséhez szükséges adatok
            nyilvántartása;
          </li>
          <li>
            Statisztikai kimutatások készítése, illetve LA-IR Bt. marketing
            tevékenységének, applikációjának és honlapjának fejlesztése az ehhez
            kapcsolódó adatok kiértékelése útján (pl.: látogatók száma,
            Felhasználók honlapon folytatott kapcsolatos tevékenysége stb.);
          </li>
          <li>Adatkezelőt érintő jogszabályi kötelezettségek teljesítése.</li>
        </ul>
        <p>3.2. Az adatkezelés célja Munkavállalók esetében:</p>
        <ul>
          <li>
            a Munkavállaló – munkaszerződés létrejöttéhez és a munkaszerződés
            teljesítéshez szükséges – azonosítása;
          </li>
          <li>
            az illetékes hatóságok felé a jogszabályok által előírt
            adatszolgáltatások és kifizetések, illetve egyéb munkaviszonyhoz
            kapcsolódó jogszabályi kötelezettségek teljesítése.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "A kezelt adtok köre – Milyen adatokat kezel az Adatkezelő?",
    content: (
      <div>
        <p>
          <strong>4.1. Felhasználók esetében:</strong>
        </p>
        <p>
          4.1.1. A honlap egyes részeinek letöltésekor cookie-k kerülnek
          elhelyezésre a Felhasználó számítógépén. A cookie-k olyan kisméretű
          szövegfájlok, melyeket a számítógép és a böngésző elment Felhasználó
          honlapon végzett tevékenységéről, és amelyek lehetővé teszik a
          Felhasználó számítógépének azonosítását (a továbbiakban: tevékenységi
          adatok). A cookiek használatáról Felhasználók a honlap használatakor
          külön értesítést kapnak, ami alapján lehetőségük van a cookie-k
          használatához való hozzájárulásra. A cookie-k elhelyezése a honlap
          egyes funkcióinak működéséhez is szükséges. A cookie-k elhelyezése nem
          okoz kárt a Felhasználó számítógépében, és nem tartalmaz vírust.
        </p>
        <p>
          A tevékenységi adatok zárt, informatikai algoritmussal védett
          rendszerben kerülnek tárolásra, amelyekhez kizárólag az Adatkezelő, -
          illetve a 8.1 pontban megjelölt egyéb személyek - rendelkeznek
          hozzáférési jogosultsággal. Az Adatkezelő kifejezetten vállalja, hogy
          a tevékenységi adatokat kizárólag az applikáció és a honlap
          fejlesztéséhez, marketing stratégia kidolgozásához, illetve
          statisztikai kimutatások elkészítéséhez használja fel.
        </p>
        <p>
          Magyarázat: Az Adatkezelő szeretné a szolgáltatásait úgy fejleszteni,
          hogy azokat a legkönnyebb és legkényelmesebb módon lehessen igénybe
          venni. Éppen ezért a honlap a működés során alkalmaz úgynevezett
          cookiekat. A cookiek kisméretű szövegfájlok, amiket az oldal a
          beleegyezésed esetén elhelyez a számítógépeden (de nem okoznak
          semmilyen problémát, vagy károsodást). Ezt követően a honlap tudja
          figyelni, hogy a számítógépeden keresztül mit nézel meg a honlapon.
          Fontos, hogy ezeket az adatokat az Adatkezelő csak arra használja,
          hogy statisztikákat készítsen és fejlessze a honlapot, az applikációt,
          illetve a marketing tevékenységét.
        </p>
        <p>
          <strong>4.2. Ügyfelek esetében:</strong>
        </p>
        <p>
          4.2.1. A honlap egyes részeinek letöltésekor cookie-k kerülnek
          elhelyezésre az Ügyfél számítógépén. A cookie-k olyan kisméretű
          szövegfájlok, melyeket a számítógép és a böngésző elment az Ügyfél
          honlapon végzett tevékenységéről, és lehetővé teszik az Ügyfél
          számítógépének azonosítását (a továbbiakban: tevékenységi adatok). A
          cookie-k használatáról az Ügyfelek a honlap használatakor külön
          értesítést kapnak, ami alapján lehetőségük van a cookie-k
          használatához való hozzájárulásra. A cookie-k elhelyezése a honlap
          egyes funkcióinak működéséhez is szükséges. A cookie-k elhelyezése nem
          okoz kárt az Ügyfél számítógépében, és nem tartalmaz vírust.
        </p>
        <p>
          A tevékenységi adatok zárt, informatikai algoritmussal védett
          rendszerben kerülnek tárolásra, amelyekhez kizárólag az Adatkezelő, -
          illetve a 8.1 pontban megjelölt egyéb személyek - rendelkeznek
          hozzáférési jogosultsággal. Az Adatkezelő kifejezetten vállalja, hogy
          a tevékenységi adatokat kizárólag a honlap és az applikáció
          fejlesztéséhez, marketing stratégia kidolgozásához, illetve
          statisztikai kimutatások elkészítéséhez használja fel.
        </p>
        <p>
          Magyarázat: Az Adatkezelő szeretné a szolgáltatásait úgy fejleszteni,
          hogy azokat a legkönnyebb és legkényelmesebb módon lehessen igénybe
          venni. Éppen ezért a honlap a működés során alkalmaz úgynevezett
          cookiekat. A cookiek kisméretű szövegfájlok, amiket az oldal a
          beleegyezésed esetén elhelyez a számítógépeden (de nem okoznak
          semmilyen problémát, vagy károsodást). Ezt követően a honlap tudja
          figyelni, hogy a számítógépeden keresztül mit nézel meg a honlapon.
          <br />
          Fontos, hogy ezeket az adatokat az Adatkezelő csak arra használja,
          hogy statisztikákat készítsen és fejlessze a honlapot, az applikációt,
          illetve a marketing tevékenységét.
        </p>
        <p>
          4.2.2. Az Ügyfél telefonon, postai úton, vagy e-mailben érdeklődhet
          LA-IR Bt. szolgáltatásairól.
        </p>
        <p>
          a.) A telefonos érdeklődés: Amennyiben az érdeklődés során a választ
          Adatkezelő meg tudja adni, abban az esetben Adatkezelő rendszerében
          adat nem kerül rögzítésre, adatkezelés nem történik.
        </p>
        <p>
          Amennyiben az Adatkezelő a kért információkat az érdeklődés során nem
          tudja megadni, és az Ügyfél telefonos visszahívásra, postán, vagy
          e-mailben megküldött válaszra tart igényt, abban az esetben meg kell
          adnia az alábbi adatokat:
        </p>
        <ul>
          <li>név</li>
          <li>telefonszám/ postacím/ e-mailcím;</li>
          <li>
            az érdeklődéssel kapcsolatos egyéb adatok (pl.: az érdeklődéssel
            érintett szolgáltatás).
          </li>
        </ul>
        <p>
          b.) A postai és e-mail érdeklődés: Ha az Ügyfél az érdeklődés kapcsán
          telefonos visszahívásra tart igényt, akkor az alábbi adatokat kell
          megadnia:
        </p>
        <ul>
          <li>név</li>
          <li>telefonszám</li>
          <li>
            az érdeklődéssel kapcsolatos egyéb adatok (pl.: az érdeklődéssel
            érintett szolgáltatás).
          </li>
        </ul>
        <p>
          Ha az Ügyfél az érdeklődés kapcsán postai/ e-mail válaszra tart
          igényt, akkor az alábbi adatokat kell megadnia:
        </p>
        <ul>
          <li>név</li>
          <li>
            az érdeklődéssel kapcsolatos egyéb adatok (pl.: az érdeklődéssel
            érintett szolgáltatás).
          </li>
        </ul>
        <p>
          A fentiek mellett ilyen esetben az Adatkezelő az Ügyfél postacímét/
          e-mailcímét is kezeli (Amennyiben a postacím/ e-mailcím az érdeklődés
          során nem került megadásra, abban az esetben az Ügyfélnek azt külön
          meg kell adnia).
        </p>
        <p>
          4.2.3. A LA-IR Bt. applikációs szolgáltatásainak igénybevétele az
          alábbi személyes, valamint nem személyes adatok megadása mellett
          lehetséges.
        </p>
        <p>
          4.2.3.1. Az applikációban való regisztráció során megadandó adatok:
        </p>
        <ul>
          <li>Ügyfél neve</li>
          <li>Ügyfél címe/székhelye</li>
          <li>Ügyfél születési ideje és ezáltal kora</li>
          <li>Ügyfél neme</li>
          <li>Ügyfél telefonszáma</li>
          <li>Ügyfél e-mailcíme</li>
          <li>Ügyfél kutyájának neve</li>
          <li>Ügyfél kutyájának születési ideje és ezáltal kora</li>
          <li>Ügyfél kutyájának neme</li>
          <li>Ügyfél kutyájának ivartalanítására vonatkozó információ</li>
          <li>Ügyfél kutyájának fajtája</li>
          <li>Ügyfél kutyájának mérete</li>
          <li>Ügyfél kutyájának viselkedési jellemzői</li>
          <li>kutyasétáltatás jellemző helyszíne</li>
          <li>kutyasétáltatás jellemző időpontja</li>
          <li>Bemutatkozás</li>
          <li>
            A szerződés teljesítéséhez szükséges egyéb adatok, információk és
            iratok
          </li>
        </ul>
        <p>4.2.3.2. Az applikáció használata során megadandó adatok:</p>
        <ul>
          <li>
            Helyzetmeghatározás adatai, amelyeket az applikáció használata
            esetén az applikáció folyamatosan használ (akkor is amikor az
            alkalmazás a háttérben fut/background location)
          </li>
          <li>
            Az applikáció használata során tett séták adatai (tartam, helyszín
            stb.), amelyet az applikáció naplóz
          </li>
          <li>
            A szerződés teljesítéséhez szükséges egyéb adatok, információk és
            iratok.
          </li>
        </ul>
        <p>
          4.2.4. A regisztráció során és az applikáció használata során megadott
          alábbi adatok más Ügyfelek számára is láthatók az applikációban.
          Ügyfél kifejezetten hozzájárul ezen adatai más Ügyfelek számára való
          hozzáférhetővé tételéhez, figyelemmel arra, hogy azok az applikációs
          szolgáltatás igénybevételéhez szükségesek.
        </p>
        <ul>
          <li>Ügyfél neve</li>
          <li>Ügyfél címe/székhelye</li>
          <li>Ügyfél születési ideje és ezáltal kora</li>
          <li>Ügyfél neme</li>
          <li>Ügyfél telefonszáma</li>
          <li>Ügyfél e-mailcíme</li>
          <li>Ügyfél kutyájának neve</li>
          <li>Ügyfél kutyájának születési ideje és ezáltal kora</li>
          <li>Ügyfél kutyájának neme</li>
          <li>Ügyfél kutyájának ivartalanítására vonatkozó információ</li>
          <li>Ügyfél kutyájának fajtája</li>
          <li>Ügyfél kutyájának mérete</li>
          <li>Ügyfél kutyájának viselkedési jellemzői</li>
          <li>kutyasétáltatás jellemző helyszíne</li>
          <li>kutyasétáltatás jellemző időpontja</li>
          <li>Bemutatkozás</li>
          <li>
            Helyzetmeghatározás adatai, amelyeket az applikáció használata
            esetén az applikáció folyamatosan használ. (akkor is amikor az
            alkalmazás a háttérben fut/background location)
          </li>
        </ul>
        <p>
          4.2.5. A LA-IR Bt. applikációs szolgáltatásainak igénybevétele során
          az Ügyfelek üzeneteket küldhetnek egymásnak. Az üzenetek
          vonatkozásában Adatkezelő csak az üzenetek tárolását, illetve
          továbbítását végzi, az üzenetek tartalmát nem ismeri, illetve
          ismerheti meg.
        </p>
        <p>
          4.2.6. A Felhasználó és az Ügyfél a LA-IR Bt. honlapján, valamint az
          Ügyfél a LA-IR Bt. applikációs szolgáltatásainak igénybevétele során
          feliratkozhat a LA-IR Bt. hírlevél-listájára. A hírlevél adatbázisban
          az Adatkezelő az alábbi adatokat kezeli:
        </p>
        <p>A hírlevél adatbázisban az Adatkezelő az alábbi adatokat kezeli:</p>
        <ul>
          <li>Név</li>
          <li>E-mail cím</li>
        </ul>
        <p>
          <strong>4.3. Munkavállalók esetében:</strong>
        </p>
        <p>
          4.3.1. A LA-IR Bt. a Munkavállalók és a munkaviszony vonatkozásában az
          alábbi adatokat kezeli, illetve kezelheti:
        </p>
        <ul>
          <li>Név</li>
          <li>Születési hely, idő</li>
          <li>Anyja neve</li>
          <li>Lakcím</li>
          <li>Adószám</li>
          <li>Személyi igazolvány száma</li>
          <li>Taj szám</li>
          <li>bankszámlaszám</li>
          <li>telefonszám</li>
          <li>e-mail cím</li>
          <li>végzettségre, képesítésre, képzettségre vonatkozó adatok</li>
          <li>
            munkabérre/ díjazásra, illetve bér- adó- és járulékfizetésre
            vonatkozó adatok
          </li>
          <li>esetleges hatósági tájékoztatásban szereplő adatok.</li>
        </ul>
        <p>
          <strong>
            4.4. Adatvédelemhez való jog és a választás lehetősége
          </strong>
        </p>
        <p>
          Felhasználóként és Ügyfélként megválaszthatod, hogy milyen
          információkat osztasz meg velünk. Természetesen a szerződésünk
          teljesítéséhez szükségünk van egyes információkra. Ehhez azonban nem
          mindig szükséges minden adat, amelyet a rendelkezésünkre bocsátasz. A
          következő lépéseket teheted meg annak érdekében, hogy kevesebb
          információt közölj magadról:
        </p>
        <ul>
          <li>
            Sütik: További kiegészítőket telepíthetsz a böngésződbe, amelyek
            blokkolják a felesleges sütiket. Ha így teszel, akkor például nem
            fogsz látni érdeklődésen alapuló hirdetéseket.
          </li>
          <li>
            Hirdetés: Ha nem szeretnél hírleveleket kapni tőlünk, bármikor
            leiratkozhatsz. Ebben az esetben nem tudunk majd tájékoztatni Téged
            az applikáció újdonságairól.
          </li>
          <li>
            Adatmegosztás megtagadása: Sajnáljuk, ha egyáltalán nem akarsz
            információkat megosztani velünk. Ebben az esetben nem tudunk
            meggyőzni arról, hogy milyen kiváló a termékünk, illetve a LA-IR Bt.
            applikációs szolgáltatásainak igénybevétele sem lesz lehetséges
          </li>
        </ul>
      </div>
    ),
  },
  {
    title:
      "Az adatkezelés jogalapja – Milyen jogon kezel adatokat az Adatkezelő?",
    content: (
      <div>
        <p>
          5.1. Személyes adatok esetében: Az Adatkezelő adatkezelési jogköre a
          Személyes adat kapcsán érintett hozzájárulásán alapul.
          <u>
            A hozzájárulását a Személyes adat kapcsán érintett bármikor
            visszavonhatja
          </u>
        </p>
        <p>
          Egyes személyes adatok kezelésének jogalapja továbbá az, hogy az
          adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben a
          Személyes adat kapcsán érintett az egyik fél, vagy az a szerződés
          megkötését megelőzően az ő kérésére történő lépések megtételéhez
          szükséges. A fentiek mellett egyes személyes adatok (pl.:
          könyvvezetéshez szükséges adatok) kezelését jogszabály is előírja.
        </p>
        <p>Magyarázat</p>
        <p>
          Az Adatkezelő azért kezelhet rád vonatkozó személyes adatokat, mert:
        </p>
        <ul>
          <li>
            Te, mint Személyes adat kapcsán érintett ehhez hozzájárultál. A
            hozzájárulást bárikor visszavonhatod;
          </li>
          <li>
            ezekre az adatokra olyan általad kért lépések megtétele miatt van
            szükség, amik egy köztetek létrejövő szerződés megkötéséhez
            szükségesek;
          </li>
          <li>
            ezekre az adatokra egy köztetek létrejövő szerződés teljesítése
            miatt van szükség
          </li>
          <li>
            az Adatkezelő számára az adatkezelést jogszabály írja elő/ teszi
            lehetővé.
          </li>
        </ul>
        <p>
          5.2. Nem személyes adatok esetében: Az adatkezelés Felhasználó, Ügyfél
          vagy Munkavállaló azon nyilatkozatán alapul, amely szerint az átadott
          adatokat Adatkezelő jogszerűen kezelheti.
        </p>
        <p>
          Magyarázat: Az Adatkezelő azért kezelhet nem személyes adatokat, mert
          Te, mint Felhasználó, Ügyfél vagy Munkavállaló úgy nyilatkoztál, hogy
          azokat jogszerűen kezelheti, azaz nem esnek pl.: üzleti titok, védett
          ismeret stb. kategóriába
        </p>
      </div>
    ),
  },
  {
    title:
      "Az adatkezelés folyamata – Hogyan és meddig kezeli az adatokat az Adatkezelő?",
    content: (
      <div>
        <p>
          <strong>6.1. Felhasználók által megadott adatok kezelése:</strong>
        </p>
        <p>
          6.1.1. A cookie-k által rögzített adatok kezelése:
          <br />A cookie-k által rögzített tevékenységi adatok Adatkezelő zárt,
          informatikai algoritmussal védett rendszerében kerülnek rögzítésre,
          amelyhez kizárólag a 8.1 pontban meghatározott személyek rendelkeznek
          hozzáférési jogosultsággal. Az Adatkezelő ezeket az adatokat az
          adatkezelés céljának teljesüléséig kezeli, a kezelés szükségességét
          pedig legfeljebb 3 évente dokumentáltan felülvizsgálja. Az így készült
          dokumentációt Adatkezelő 10 évig őrzi meg
        </p>
        <p>
          Magyarázat: Ha a honlapon található cookie-k rögzítették az
          IP-címedet, illetve a honlapon végzett tevékenységedet, akkor az ezzel
          kapcsolatos adatokat Adatkezelő egy informatikai kóddal védett
          rendszerben helyezi el. Az adatokat Adatkezelő addig kezeli, amíg a
          számára lényeges statisztikai, fejlesztési, vagy marketing
          információkat meg nem szerzi. Az adatok kezelésének szükségességét
          adatkezelő 3 évente újra megvizsgálja.
        </p>
        <p>
          <strong>6.2. Az Ügyfél által megadott adatok kezelése:</strong>
        </p>
        <p>6.1.2. A hírlevél adatbázisban nyilvántartott adatok kezelése:</p>
        <p>
          A Felhasználó és az Ügyfél az Adatkezelő honlapján önkéntes, egyoldalú
          és kifejezett nyilatkozata útján hozzájárulhat a következő adatainak
          hírlevél adatbázisban történő rögzítéséhez: név és e-mail cím.
          <br /> A hírlevél adatbázis adatai egy elkülönült zárt rendszerben
          kerülnek tárolásra. Az Adatkezelő kifejezetten vállalja, hogy a
          hírlevél adatbázisban szereplő adatállományt nyilvánosságra nem hozza,
          illetve nem adja ki illetéktelen harmadik személyek számára.{" "}
          <u>
            A hírlevél adatbázisban rögzített, Felhasználóhoz és Ügyfélhez
            kapcsolódó adatok a Felhasználó és az Ügyfél egyedi leiratkozását
            követően a lehető legrövidebb idő alatt törlésre kerülnek az
            Adatkezelő hírlevél adatbázisából.
          </u>
        </p>
        <p>
          Magyarázat: Az Adatkezelő honlapján feliratkozhatsz a LA-IR Bt.
          hírlevelére. Az itt megadott adatokat Adatkezelő informatikai kóddal
          védett rendszerben tárolja, és nem adja ki másoknak. Ha a továbbiakban
          nem szeretnél Adatkezelőtől hírleveleket kapni, akkor bármikor
          leiratkozhatsz. Ilyen esetben Adatkezelő az adataidat a lehető
          legrövidebb időn belül törli.
        </p>
        <p>
          6.2.1. A cookie-k által rögzített adatok kezelése: <br />A cookie-k
          által rögzített tevékenységi adatok Adatkezelő zárt, informatikai
          algoritmussal védett rendszerében kerülnek rögzítésre, amelyhez
          kizárólag a 8.1 pontban meghatározott személyek rendelkeznek
          hozzáférési jogosultsággal. Az Adatkezelő ezeket az adatokat az
          adatkezelés céljának teljesüléséig kezeli, a kezelés szükségességét
          pedig legfeljebb 3 évente dokumentáltan felülvizsgálja. Az így készült
          dokumentációt Adatkezelő 10 évig őrzi meg.
        </p>
        <p>
          Magyarázat: Ha a honlapon található cookie-k rögzítették az
          IP-címedet, illetve a honlapon végzett tevékenységedet, akkor az ezzel
          kapcsolatos adatokat Adatkezelő egy informatikai kóddal védett
          rendszerben helyezi el. Az adatokat Adatkezelő addig kezeli, amíg a
          számára lényeges statisztikai, fejlesztési, vagy marketing
          információkat meg nem szerzi. Az adatok kezelésének szükségességét
          adatkezelő 3 évente újra megvizsgálja.
        </p>
        <p>
          6.2.2. Az érdeklődés során megadott adatok kezelése: Az Ügyfelek
          telefonon, postai úton, vagy e-mailben, érdeklődhetnek LA-IR Bt.
          applikációs szolgáltatásairól.
        </p>
        <p>
          Amennyiben a telefonos érdeklődés során az Adatkezelő a választ egyből
          meg tudja adni, akkor adatkezelés nem történik.
        </p>
        <p>
          Ha az Adatkezelő a választ egyből nem tudja megadni, vagy az Ügyfél
          postai úton, vagy emailben érdeklődik, abban az esetben az adatkezelés
          jogalapja az, hogy az adatok kezelése az Ügyfél által kért, szerződést
          megelőző lépések megtételéhez szükséges.
        </p>
        <p>
          Ha az érdeklődést követően az Adatkezelő és az Ügyfél között szerződés
          jön létre, és az adott adatok a szerződés teljesítéséhez szükségesek,
          vagy hasznosak, abban az esetben Adatkezelő az Ügyfél hozzájárulását
          kéri az adatkezeléshez. Amennyiben szerződéskötésre nem kerül sor,
          akkor Adatkezelő az érdeklődés során megadott adatokat törli, vagy
          megsemmisíti.
        </p>
        <p>
          Az érdeklődés során megadott adatok Adatkezelő részére történő
          átadásukat követően Adatkezelő zárt, informatikai algoritmussal védett
          rendszerében kerülnek rögzítésre, amelyhez kizárólag a 8.1 pontban
          meghatározott személyek rendelkeznek hozzáférési jogosultsággal.
          Amennyiben az Ügyfél által megadott adatok papír alapon kerülnek
          rögzítésre, abban az esetben azokat Adatkezelő biztonságtechnikai
          szempontból megfelelően zárható épületben található helyiségben,
          szükség esetén zárható tárolóhelyen, rendszerezetten őrzi, és ahhoz
          csak a 8.1. pontban meghatározott személyek rendelkeznek hozzáférési
          jogosultsággal.
        </p>
        <p>
          Magyarázat: Három féle módon érdeklődhetsz LA-IR Bt. informatikai
          szolgáltatásairól: telefonon, postán, vagy e-mailben. Ha Adatkezelő
          telefonon nem tud Neked egyből választ adni, akkor a megadott
          adataidat azért kezelheti, mert ezek kellenek ahhoz, hogy megadja a
          választ, amit kértél. Ugyanez a helyzet a postai, vagy az e-mailes
          érdeklődések esetén is. Ha az érdeklődés után szerződést kötsz az
          LA-IR Bt-vel, akkor az Adatkezelő megkérhet Téged arra, hogy az
          adataid kezeléséhez járulj hozzá. Ha viszont szerződés nem jön létre,
          akkor az Adatkezelő törli, vagy megsemmisíti az adataidat.
          <br /> Az Adatkezelő az elektronikusan rögzített adatokat informatikai
          kóddal védett rendszerben, a papír alapon rögzített adatokat
          biztonságos helyiségben és tárolóhelyen tárolja egészen addig, amíg az
          adatkezelés célja meg nem valósul.
        </p>
        <p>
          6.2.3. Az applikációs szolgáltatás igénybevételéhez szükséges, a
          regisztráció és az applikáció használata során megadott adatok
          kezelése: Ezeket az adatokat az Ügyfél az applikációban adhatja meg.
          Az adatkezelés megkezdése előtt az Adatkezelő minden esetben az Ügyfél
          hozzájárulását kéri. Az így megadott adatok az Adatkezelő zárt,
          informatikai algoritmussal védett rendszerében kerülnek rögzítésre,
          amelyhez kizárólag a 8.1 pontban meghatározott személyek rendelkeznek
          hozzáférési jogosultsággal.
          <br /> Amennyiben az Ügyfél által megadott adatok papír alapon
          kerülnek rögzítésre, abban az esetben azokat Adatkezelő
          biztonságtechnikai szempontból megfelelően zárható épületben található
          helyiségben, szükség esetén zárható tárolóhelyen, rendszerezetten
          őrzi, és ahhoz csak a 8.1. pontban meghatározott személyek
          rendelkeznek hozzáférési jogosultsággal.
        </p>
        <p>
          Az Adatkezelő ezeket az adatokat az adatkezelés céljának teljesüléséig
          kezeli, a kezelés szükségességét pedig legfeljebb 3 évente
          dokumentáltan felülvizsgálja. Az így készült dokumentációt Adatkezelő
          10 évig őrzi meg. Amennyiben az adatokat az Adatkezelő jogszabályi
          előírás (pl.: könyvvezetési kötelezettség stb.) alapján kezeli, abban
          az esetben azokat a jogszabályban meghatározott ideig tartja nyilván
          és kezeli.
        </p>
        <p>
          Magyarázat: Ahhoz, hogy az LA-IR Bt. applikációs szolgáltatásait
          igénybe vedd, meg kell adnod a fent említett adatokat. Az Adatkezelő
          az ilyen elektronikusan rögzített adatokat informatikai kóddal védett
          rendszerben, a papír alapon rögzített adatokat biztonságos helyiségben
          és tárolóhelyen tárolja, egészen addig, amíg az adatkezelés célja meg
          nem valósul. A tárolás és kezelés szükségességét az Adatkezelő 3
          évente ismét megvizsgálja. Ha viszont az Adatkezelő azért kezeli az
          adatokat, mert jogszabály írja elő neki (pl.: a könyvvezetéshez
          kapcsolódó adatok), akkor az adatokat a jogszabályban meghatározott
          ideig kezeli és tárolja.
        </p>
        <p>6.2.4. A hírlevél adatbázisban nyilvántartott adatok kezelése:</p>
        <p>
          Az Ügyfél a honlap, illetve az Adatkezelő applikációs szolgáltatásának
          igénybevétele során önkéntes, egyoldalú és kifejezett nyilatkozata
          útján hozzájárulhat a következő adatainak hírlevél adatbázisban
          történő rögzítéséhez: név és e-mail cím. A hírlevél adatbázis adatai
          egy elkülönült zárt rendszerben kerülnek tárolásra. Az Adatkezelő
          kifejezetten vállalja, hogy a hírlevél adatbázisban szereplő
          adatállományt nyilvánosságra nem hozza, illetve nem adja ki
          illetéktelen harmadik személyek számára.{" "}
          <u>
            A hírlevél adatbázisban rögzített, Ügyfélhez kapcsolódó adatok az
            Ügyfél egyedi leiratkozását követően a lehető legrövidebb idő alatt
            törlésre kerülnek az Adatkezelő hírlevél adatbázisából.
          </u>
        </p>
        <p>
          Magyarázat: A honlap használata, illetve a LA-IR Bt. applikációs
          szolgáltatásainak igénybevétele során feliratkozhatsz a LA-IR Bt.
          hírlevelére. Az itt megadott adatokat Adatkezelő informatikai kóddal
          védett rendszerben tárolja, és nem adja ki másoknak. Ha a továbbiakban
          nem szeretnél Adatkezelőtől hírleveleket kapni, akkor bármikor
          leiratkozhatsz. Ilyen esetben Adatkezelő az adataidat a lehető
          legrövidebb időn belül törli
        </p>
        <p>
          <strong>
            6.4. A Munkavállalók által megadott és a munkaviszonyhoz kapcsolódó
            adatok kezelése:
          </strong>
        </p>
        <p>
          A Munkavállaló által megadott adatok kezelésének elsődleges célja a
          munkaszerződés létrehozása és teljesítése, illetve a Munkáltató
          munkaviszonnyal kapcsolatos jogszabályi kötelezettségeinek teljesítése
        </p>
        <p>
          Az Adatkezelő a munkaviszony létesítésekor a Munkavállalótól, a
          munkaviszony fennállása alatt pedig a Munkavállalótól, saját
          szervezetéből, illetve az illetékes hatóságoktól szerzi be a
          munkaviszonyhoz kapcsolódó adatokat.
        </p>
        <p>
          Ezek az adatok az Adatkezelő zárt, informatikai algoritmussal védett
          rendszerében kerülnek rögzítésre, amelyhez kizárólag a 8.1. pontban
          meghatározott személyek rendelkeznek hozzáférési jogosultsággal.
          <br /> A fentiek mellett a papír alapon beérkezett, vagy rögzített
          adatokat Adatkezelő biztonságtechnikai szempontból megfelelően zárható
          épületben található helyiségben, szükség esetén zárható tárolóhelyen,
          rendszerezetten őrzi, és ahhoz csak a 8.1. pontban meghatározott
          személyek rendelkeznek hozzáférési jogosultsággal.
        </p>
        <p>
          Azokat az adatokat, amelyek kezelésének jogalapja kizárólag a
          Munkavállaló hozzájárulása, Adatkezelő az adatkezelés céljának
          teljesüléséig kezeli, a kezelés szükségességét pedig legfeljebb 3
          évente dokumentáltan felülvizsgálja. Az így készült dokumentációt
          Adatkezelő 10 évig őrzi meg.
          <br /> Azokat az adatokat, amelyek kezelésének jogalapja jogszabályi
          kötelezés, vagy felhatalmazás, Adatkezelő a vonatkozó jogszabályban
          meghatározott időtartamig kezeli, illetve őrzi meg.
        </p>
        <p>
          Magyarázat: A munkaviszonnyal kapcsolatos fenti elektronikus adatokat
          az Adatkezelő informatikai kóddal védett rendszerben tárolja. Ha az
          adatok papír alapon állnak rendelkezésre, akkor azokat adatkezelő
          rendszerezetten, biztonságos helyiségben, illetve szükség esetén
          zárható tárolóhelyen őrzi. Ha az Adatkezelő csak azért kezelheti az
          adatokat, mert Te, mint Munkavállaló ahhoz hozzájárultál, akkor 3
          évente felül kell vizsgálnia azt, hogy továbbra is szükséges-e az
          adatok kezelése. Ha olyan adatokról van szó, amiket Adatkezelő
          jogszabály alapján kezel, akkor azokat addig kezeli, amíg a jogszabály
          előírja részére.
        </p>
      </div>
    ),
  },
  {
    title: "Adatbiztonság – Hogyan védi az Adatkezelő a kezelt adatokat?",
    content: (
      <div>
        <p>
          7.1. Adatkezelő a 4. pontban meghatározott adatokat (a továbbiakban:
          kezelt adatok) zárt, informatikai algoritmussal védett rendszerben
          tárolja, és adatvédelmi szempontból biztonságos, informatikai
          algoritmussal védett, zárt szoftverekkel kezeli. <br />A papír alapon
          rendelkezésre álló adatokat Adatkezelő biztonságtechnikai szempontból
          megfelelően zárható épületben található helyiségben, szükség esetén
          zárható tárolóhelyen rendszerezetten őrzi.
          <br /> Adatkezelő kötelezettséget vállal arra, hogy minden szükséges
          és ésszerű lépést megtesz annak érdekében, hogy a kezelt adatokat -
          mind a hálózati kommunikáció, mind az adatok tárolása és őrzése során
          - a jogosulatlan hozzáféréstől, megváltoztatástól, továbbítástól,
          nyilvánosságra hozataltól, törléstől, vagy megsemmisítéstől, valamint
          a véletlen megsemmisüléstől és sérüléstől, továbbá az alkalmazott
          technika megváltozásából fakadó hozzáférhetetlenné válástól megóvja.
        </p>
        <p>
          Magyarázat: Adatkezelő a nála lévő elektronikus adatokat informatikai
          kóddal védett rendszerben tárolja, és a kezelésük során csak olyan
          szoftvert alkalmaz, ami szintén ilyen kóddal védett. A papír alapú
          iratokat Adatkezelő biztonságos helyiségben, elzártan tárolja.
          Adatkezelő megóvja az adatokat attól, hogy azokat jogosulatlan személy
          megismerje/ megismerhesse, azok megváltozzanak, elérhetetlenek
          legyenek, vagy megsemmisüljenek
        </p>
      </div>
    ),
  },
  {
    title:
      "Az adatkezelők és az adatfeldolgozók köre – Kik kezelik az LA-IR Bt. részére átadott adatokat?",
    content: (
      <div>
        <p>
          8.1. Adatkezelő: A 4. pontban meghatározott adatok kezelésére, illetve
          a tárolt adatok megismerésére kizárólag LA-IR Bt., valamint az LA-IR
          Bt-vel munkaviszonyban, munkavégzésre irányuló egyéb jogviszonyban
          álló személyek jogosultak (a továbbiakban együtt: Adatkezelő). Az
          Adatkezelő az adatok kezelését célhoz kötötten végzi, az adatokat a 3.
          pontban meghatározott célú felhasználáson túl nem használja fel.
        </p>
        <p>
          Magyarázat: Az LA-IR Bt. részére átadott adatokat kizárólag az LA-IR
          Bt., az ő dolgozói, illetve a vele ehhez hasonló kapcsolatban állók
          kezelhetik. Az adatkezelés során mindannyian csak az adatkezelés
          céljának megfelelően járhatnak el.
        </p>
        <p>
          8.2. Adatfeldolgozó: Adatkezelő egyes jogszabályban és szerződésben
          rögzített kötelezettségeinek teljesítése érdekében Adatfeldolgozókat
          alkalmaz.
        </p>
        <p>
          8.2.1. Adatkezelő a kezelésében lévő egyes adatokat az alábbi tárhely
          szolgáltató (a továbbiakban: Tárhely szolgáltatók) által biztosított
          tárhelyen tárolja:
        </p>

        <p>
          név: Amazon Web Services Inc.
          <br />
          képviselő: a cégjegyzék adatai alapján képviseletre jogosult személy
          <br />
          cím: 1200 12th Avenue South Suite 1200 Seattle, WA 98144 Egyesült
          Államok
        </p>
        <p>
          név: Firebase - Google LLC <br />
          képviselő: a cégjegyzék adatai alapján képviseletre jogosult személy
          <br />
          cím: 1600 Amphitheatre Parkway, Mountain View, California 94043,
          United States
        </p>
        <p>
          név: Twilio - Twilio Inc.
          <br />
          képviselő: a cégjegyzék adatai alapján képviseletre jogosult személy
          <br />
          cím: 375 Beale Street, Suite 300, San Francisco, CA 94105, United
          States
        </p>
        <p>
          név: Google Cloud - Google LLC <br />
          képviselő: a cégjegyzék adatai alapján képviseletre jogosult személy
          <br />
          cím: 1600 Amphitheatre Parkway, Mountain View, California 94043,
          United States
        </p>
        <p>
          név: Render - Render Services Inc.
          <br />
          képviselő: a cégjegyzék adatai alapján képviseletre jogosult személy
          <br />
          cím: 3611 S Iron St, Chicago, IL 60609-1322 United States
        </p>
        <p>
          Adatkezelő részére átadott adatok kapcsán Tárhely szolgáltatók
          kizárólag az adatok tárolását biztosítják, egyéb adatkezelési
          műveletet nem végeznek. Tárhely szolgáltatók az adatok tárolásához
          szükséges tárhelyet az Adatkezelővel kötött szerződés teljesítéséhez
          szükséges ideig biztosítják.
        </p>
        <p>
          8.2.2. Adatkezelő jogszabályban meghatározott könyvvezetési
          kötelezettségnek teljesítése érdekében az alábbi könyvelőt (a
          továbbiakban: Könyvelő) alkalmazza:
        </p>
        <p>
          név: Deák Gábor E.V.
          <br />
          cím: 4400 Nyíregyháza, Szalag utca 4/22
        </p>
        <p>
          Adatkezelő Könyvelő részére kizárólag a könyvvezetési kötelezettség
          teljesítéséhez szükséges adatokat adja át. Könyvelő az így átadott
          adatokat a vonatkozó jogszabályokban meghatározott ideig tárolja.
        </p>
        <p>
          8.2.3. Adatkezelő a jogszabályban meghatározott egészségügyi
          alkalmassági vizsgálat elvégzésével az alábbi üzemorvost (a
          továbbiakban: Üzemorvos) bízza meg:
        </p>
        <p>
          név: Dr. Nagy Gabriella
          <br />
          cím: 4400 Nyíregyháza, Szent István utca 14.
        </p>
        <p>
          Adatkezelő az Üzemorvos részére kizárólag az egészségügyi alkalmassági
          vizsgálat elvégzéséhez szükséges adatokat adja át.
          <br />
          Üzemorvos az Adatkezelőtől kapott adatokat az egészségügyi
          alkalmassági vizsgálat elvégzéséhez, illetve az Adatkezelővel kötött
          szerződés teljesítéséhez szükséges és a jogszabály által előírt ideig
          tárolja
        </p>
        <p>
          Magyarázat: Az LA-IR Bt. részére a fenti Tárhely szolgáltató
          biztosítja a tárhelyet. Tárhely szolgáltató a tárolt adatokat nem
          látja és azokkal egyéb adatkezelési műveletet nem tud végezni. Az
          adatok addig maradnak a tárhelyen, amíg Tárhely szolgáltató és az
          LA-IR Bt. közötti szerződés előírja. Külön is felhívjuk a figyelmedet
          arra, hogy a tárhely szolgáltatás egyes esetekben az EGT-n kívüli
          államban folyik!
        </p>
        <p>
          Az LA-IR Bt. a fenti Könyvelőt alkalmazza a könyvvezetési feladatok
          elvégzéséhez. Ahhoz, hogy a Könyvelő a jogszabályoknak megfelelően
          tudja ellátni a feladatát, meg kell, hogy kapja az ehhez szükséges
          adatokat, amiket Adatkezelő ad át neki. Az ilyen adatokat a Könyvelő
          addig tárolja, amíg a jogszabály előírja neki.
          <br /> Az egészségügyi alkalmassági vizsgálatot a fenti Üzemorvos
          végzi, aki ehhez adatokat kap Adatkezelőtől. Az adatokat Üzemorvos a
          vizsgálat elvégzéséhez és az LA-IR Bt-vel kötött szerződés
          teljesítéséhez szükséges ideig, vagy addig kezeli, amíg jogszabály
          előírja neki.
        </p>
        <p>
          8.2.4. Adatkezelő a részére átadott egyes adatokat a következő eszköz
          útján tárolja és kezeli: Hírlevélre történő feliratkozás során
          megadott adatok:
        </p>
        <ul>
          <li>
            név: MailChimp
            <br />
            képviselő: a cégjegyzékben képviselőként megjelölt személy
            <br />
            cím: cégjegyzékben megadott cím
          </li>
        </ul>
        <p>
          Magyarázat: Adatkezelő egyes adatok tárolásához és kezeléséhez a fenti
          eszközt használja. Az eszköz tulajdonosai és üzemeltetői kizárólag
          olyan tevékenységet végeznek, ami ahhoz szükséges, hogy Adatkezelő az
          adatokat az eszköz által tárolni és kezelni tudja. Ezt a tevékenységet
          pedig addig folytatja, amíg az Adatkezelővel kötött szerződés
          teljesítése miatt szükséges. Külön is felhívjuk a figyelmedet arra,
          hogy az eszközök által végzett adatkezelési műveletek EGT-n kívüli
          államban folynak!
        </p>
      </div>
    ),
  },
  {
    title:
      "A Személyes adat kapcsán érintettek és a Nem személyes adat kapcsán érintettek jogai – Milyen jogaid vannak az Adatkezelő és az Adatfeldolgozó adatkezelésével kapcsolatban?",
    content: (
      <div>
        <p>
          9.1. Hozzáféréshez való jog: A fenti személyek/ szervezetek bármikor
          kérhetik, hogy a rájuk vonatkozó adatokat és az azok kezelésével
          összefüggő információkat az Adatkezelő a rendelkezésükre bocsássa.
          Kérhetik továbbá azt is, hogy a rájuk vonatkozó egészségügy
          dokumentációba betekinthessenek, arról kivonatot, vagy másolatot
          készíthessenek.
        </p>
        <p>
          Magyarázat: Bármikor kérhetsz információt arról, hogy az Adatkezelő
          milyen adatodat kezeli. Információt kérhetsz még az adatok forrásáról,
          az adatkezelés céljáról, jogalapjáról, időtartamáról, az
          adatfeldolgozó nevéről, címéről és az adatkezeléssel összefüggő
          tevékenységéről. Megkérheted az Adatkezelőt arra is, hogy engedje meg,
          hogy a rád vonatkozó egészségügyi dokumentációt megnézd, arról
          kivonatot, vagy másolatot készíts.
        </p>
        <p>
          9.2. Helyesbítéshez való jog: A fenti személyek/ szervezetek bármikor
          kérhetik, hogy a rájuk vonatkozó adatokat az Adatkezelő helyesbítse,
          illetve egészítse ki.{" "}
        </p>
        <p>
          Magyarázat: Ha egy rád vonatkozó adat hibás, vagy hiányos, akkor
          kérheted, hogy az Adatkezelő azt javítsa, vagy egészítse ki.
        </p>
        <p>
          9.3. Korlátozáshoz, törléshez és megsemmisítéshez való jog: A fenti
          személyek/ szervezetek bármikor kérhetik a rájuk vonatkozó adatok
          kezelésének korlátozását, illetve az ilyen adatok törlését vagy
          megsemmisítését (ide nem értve a kötelező adatkezelés körébe eső
          adatokat).
        </p>
        <p>
          Magyarázat: Bármikor kérheted a rád vonatkozó adatok adatkezelésének
          korlátozását, vagy az adatok törlését vagy megsemmisítését is.
        </p>
        <p>
          9.4. Az Adatkezelő a fentiek szerinti kérelmeket a kérelem
          benyújtásától számított legrövidebb időn – de legkésőbb a kérelem
          kézhezvételétől számított 25 napon – belül köteles teljesíteni, vagy
          az elutasítás tényéről, indokairól, továbbá a jogorvoslati
          lehetőségekről a fenti személyeket/ szervezeteket tájékoztatni.
        </p>
        <p>
          Magyarázat: A kérelmedre legkésőbb a megérkezésétől számított 25 napon
          belül választ fogsz kapni. Ha az Adatkezelő a kérelmet elutasítja,
          akkor ezt meg is indokolja, és tájékoztat arról, hogy milyen
          jogorvoslati lehetőségeid vannak.
        </p>
        <p>
          9.5. A Személyes adat kapcsán érintettek a fenti jogaik megsértése
          esetén a következőkhöz fordulhatnak:
        </p>
        <ul>
          <li>
            Nemzeti Adatvédelmi és Információszabadság Hatóság (1125 Budapest,
            Szilágyi Erzsébet fasor 22/c.);
          </li>
          <li>Lakóhely/ tartózkodási hely szerint illetékes törvényszék.</li>
        </ul>
        <p>
          Magyarázat: Ha a rád vonatkozó személyes adat kapcsán sértik meg a
          jogaidat, akkor ezekhez a szervekhez fordulhatsz.
        </p>
        <p>
          9.6. A Nem személyes adat kapcsán érintett és az Adatkezelő a köztük
          felmerülő, adatkezeléssel kapcsolatos jogvitát elsődlegesen
          egyezményes úton rendezi. Ha ez nem vezet eredményre, akkor a Nem
          személyes adat kapcsán érintett a Polgári perrendtartásról szóló
          törvényben meghatározott általános szabályok szerint fordulhat
          bírósághoz
        </p>
        <p>
          Magyarázat: Ha a hozzád köthető nem személyes adat kapcsán sértik meg
          a jogaidat, akkor egyezkedés útján, vagy peres úton léphetsz fel.
        </p>
      </div>
    ),
  },
  {
    title: "Vegyes és záró rendelkezések",
    content: (
      <div>
        <p>
          10.1. Adatkezelő jogosult az Akt-t a Felhasználók, az Ügyfelek,
          illetve a Munkavállalók előzetes értesítése mellett, egyoldalúan
          módosítani. Az Akt. érdemi módosítása esetén az adatkezelés
          jogszerűségéhez – annak szükségessége esetén – a hozzájárulást
          Adatkezelőnek ismételten be kell szereznie. Az Akt-t Adatkezelő a
          honlapján, illetve az applikációban teszi közzé.
        </p>
        <p>
          Magyarázat: Az Akt-t a LA-IR Bt. honlapján, vagy az applikációban
          bármikor megtekintheted.
          <br />
          Az Adatkezelő önállóan is módosíthatja az Akt-t. Azért, hogy a tudtod
          nélkül rád ne terjedhessenek ki az új szabályok, az Adatkezelő a
          módosításról előzetesen tájékoztat Téged, és szükség esetén ismét a
          hozzájárulásodat kéri az adatkezeléshez.
        </p>
        <p>
          10.2. A Felhasználó, az Ügyfél, vagy a Munkavállaló nem adhat át az
          Adatkezelőnek olyan adatot, ami harmadik fél és/vagy az Adatkezelő
          jogát, illetve jogos érdekeit sérti.
        </p>
        <p>
          Magyarázat: Csak olyan adatot adhatsz át az Adatkezelőnek, ami nem
          sérti se az ő jogát, se egyéb személyekét
        </p>
        <p>
          10.3. Amennyiben a Felhasználó, az Ügyfél, vagy a Munkavállaló
          jogosulatlanul harmadik fél adatait adta meg, akkor Adatkezelő
          jogosult a Felhasználó, Ügyfél, vagy a Munkavállaló jognyilatkozatát
          semmisnek tekinteni, illetve a megadott adatokat törölni, valamint a
          hírlevélre történő feliratkozását törölni.
        </p>
        <p>
          10.4. Jelen Akt. 19. számozott oldalból áll, és azt LA-IR Bt. - mint
          adatkezelésének fundamentális dokumentumát - elfogadta
        </p>
      </div>
    ),
  },
];

const PrivacyPolicyPage = (props) => {
  const contents = accordionItems.reduce((acc, el) => {
    acc += el.content;
    return acc;
  });

  return (
    <I18NProvider>
      {(lng) => {
        return (
          <Layout lng={lng} location={props.location}>
            <SEO title="Adatkezelési tájékoztató" />
            <div className="privacy-policy-wrapper">
              <div className="privacy-policy-intro">
                <div>
                  <h1 style={{ fontFamily: "Dosis" }}>
                    ADATKEZELÉSI TÁJÉKOZTATÓ
                  </h1>
                </div>
                <div>
                  <p>
                    Jelen Adatkezelési tájékoztató (a továbbiakban: Akt.) célja,
                    hogy a LA-IR Kereskedelmi és Szolgáltató Bt. (cím: 4400
                    Nyíregyháza, Deák F. u. 13., cégjegyzékszám szám:
                    15-06-083828., adószám: 25672705-2-15., a továbbiakban:
                    LA-IR Bt.) adatkezelési tevékenységéhez kapcsolódó legfőbb
                    előírásokat a hatályos jogszabályi rendelkezéseknek –
                    különös tekintettel, de nem kizárólagosan a GDPR-nak és az
                    Info tv-nek – megfelelően rögzítse. Jelen Akt. a
                    Munkavállalók vonatkozásában csak az applikációs
                    szolgáltatáshoz kapcsolódó munkaviszonyok/ munkavégzésre
                    irányuló egyéb jogviszonyok keretében megadott adatok
                    kezelésére terjed ki. Az Akt. kiemelt célja továbbá, hogy az
                    érintettek részére - a hatályos jogszabályi előírásoknak
                    megfelelő - könnyen hozzáférhető formában tömör, átlátható,
                    érthető, világos és közérthető tájékoztatást nyújtson a
                    személyes adatok kezelését illetően.
                  </p>
                </div>
              </div>
              <div className="privacy-policy">
                {accordionItems.map((el, i) => {
                  return (
                    <>
                      <h2 style={{ fontFamily: "Dosis" }}>
                        {i + 1}. {el.title}
                      </h2>
                      <div key={i}>{el.content}</div>
                    </>
                  );
                })}
              </div>
            </div>
          </Layout>
        );
      }}
    </I18NProvider>
  );
};

export default PrivacyPolicyPage;
