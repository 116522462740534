import * as i18n from 'i18next';
import * as LanguageDetector from 'i18next-browser-languagedetector';
import { I18NextProvider } from 'ns-react-i18next';
import React from 'react';
import enTranslation from '../locales/en/translation.json';
import huTranslation from '../locales/hu/translation.json';


const translations = {
  en: {
    translations: enTranslation,
  },
  hu: {
    translations: huTranslation,
  },
};

i18n.use(LanguageDetector).init({
  resources: translations,
  fallbackLng: 'hu',
  debug: true,
  defaultNS: 'translations', // this namespace will be used if no namespace shared via context
  fallbackNS: 'translations',
});

export class I18NProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lng: '' };
  }
  componentDidMount() {
    i18n.changeLanguage('hu', () => {
      this.setState({ lng: 'hu' });
    });
  }
  render() {
    return <I18NextProvider i18n={i18n}>{this.props.children(this.state.lng)}</I18NextProvider>;
  }
}
